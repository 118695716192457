// i18next-extract-mark-ns-start products-wave-cs2

import { WAVE_APPLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWavePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const icon = getImage(data.icon.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Eliminating Physical Sensors</h2>
						<h3>
							New Automotive Strategy - Eliminate physical sensors
							using Realis Simulation’s WAVE-RT
						</h3>
					</Trans>
					{icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="WAVE-RT"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							For engine and vehicle manufacturers, there is a new
							opportunity to eliminate physical sensors and replace
							them with more efficient, less expensive, and more
							capable digital engine control units that are
							sensor-free. Currently, most engines rely on physical
							sensors to feed data to the engine controller; however,
							these sensors add cost and complexity to product
							development and manufacturing. They are also prone to
							failure, which in turn leads to the need for aftermarket
							product replacement. Eliminating physical sensors can
							significantly reduce manufacturing cost and also improve
							performance for the service life of the engine. In this
							study, Realis Simulation demonstrates one example of
							successfully eliminating a sensor from the engine design
							using Realis Simulation formerly Ricardo Software’s
							WAVE-RT technology.
						</p>
						<p>
							An experimental validation test compared the performance
							of an:
						</p>
						<ul>
							<li>
								IVECO 5.9 L turbocharged diesel engine with physical
								sensors
							</li>
							<li>
								The same engine with the physical sensor removed,
								and replaced by a WAVE-RT model embedded on a
								prototyping engine control unit.
							</li>
						</ul>
						<p>
							On the engine test bed, a test compared the performance
							of the two engines while running the world harmonized
							transient cycle, an 1800-second-long transient cycle
							that is representative of real-world driving. During
							testing of both engine configurations, standard metrics
							such as power, torque, and speed were recorded.
							Validated results prove that sensor-free control using
							WAVE-RT resulted in equivalent performance across the
							entire test cycle.On the engine test bed, a test
							compared the performance of the two engines while
							running the world harmonized transient cycle, an
							1800-second-long transient cycle that is representative
							of real-world driving. During testing of both engine
							configurations, standard metrics such as power, torque,
							and speed were recorded. Validated results prove that
							sensor-free control using WAVE-RT resulted in equivalent
							performance across the entire test cycle.
						</p>
						<p>
							While this configuration is not yet applicable for mass
							production in passenger vehicles, due to onboard
							computational limitations, this approach could be
							applied today on stationary and low volume engines. With
							continued improvements to onboard computing for future
							passenger vehicles and advances of Realis Simulation
							formerly Ricardo Software’s WAVE-RT to accurately
							simulate transient performance, the potential to
							eliminate sensors in high volume engine designs is on
							the horizon. This will lead to lower manufacturing costs
							and more reliable performance through the life of the
							engine. Another potential future application for this
							engine control concept is the ability to enhance engine
							reliability when sensor failure occurs. Advanced onboard
							diagnostics, in combination with fault tolerance using
							an onboard WAVE-RT model, could enable an engine and
							vehicle to continue operating despite a physical sensor
							failure.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_APPLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWavePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave-cs2", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/products/wave/rs_wavert.png" }) {
		...imageBreaker
	}
}
`;

